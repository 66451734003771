import baseUrl from "../http/baseUrl";

export const footInfo = baseUrl.indexOf("minwf.cn") > -1?`<div
      style="min-height:148px;margin:0 auto ;font-size:14px;color:rgb(200,200,200);height: auto;line-height: 26px;padding:35px 0;width: 1200px;text-align: center">
      主办单位：宁夏中宁枸杞产业创新研究院、南京中高知识产权股份有限公司、宁夏中宁枸杞产业集团有限公司<br>
      地址：宁夏回族自治区中卫市中宁县科技局<br>
      <a href="https://beian.miit.gov.cn/" target="_blank" style="color: rgb(220,220,220)">   Copyright©2021 南京中高 All Rights Reserved. 南京中高知识产权股份有限公司 版权所有
        工业和信息化部备案管理系统网站 宁B2-20090191-18</a>


    </div>`:`   <div
      style="min-height:148px;padding:60px 0;margin:0 auto ;font-size:14px;color:rgb(200,200,200);height: auto;line-height: 26px;width: 1200px;text-align: center">
      <a  style="color: rgb(200, 200, 200)" href="https://beian.miit.gov.cn/" target="_blank">连云港科技大市场版权所有2013-2021 苏ICP备17052769号-1. All Rights Reserved.</a>

    </div>`

